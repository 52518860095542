<template>
  <div class="x-handle" @mousedown="mouseDown"></div>
</template>
 
<script>
export default {
  name: "XHandle",
  data() {
    return {
      lastX: ""
    };
  },
  activated() {
    window.addEventListener('mouseup',this.mouseUp)
  },
  deactivated() {
    window.removeEventListener("mouseup", this.mouseUp);
  },
  methods: {
    mouseDown(event) {
      window.addEventListener("mousemove", this.mouseMove);
      this.lastX = event.screenX;
    },
    mouseMove(event) {
      this.$emit("widthChange", this.lastX - event.screenX);
      this.lastX = event.screenX;
    },
    mouseUp() {
      this.lastX = "";
      window.removeEventListener("mousemove", this.mouseMove);
    }
  }
};
</script>
<style>
.x-handle {
  padding: 5px;
  cursor: w-resize;
}
.x-handle:hover{
 background: #dbdcdf;
}
</style>