<template>
  <div class="wrap-mp">
    <div class="edit-header wp">
      <span>材料列表{{ ProjectName ? " - " + ProjectName : "" }}</span>
      <el-button class="w-btn"
                 round
                 type="warning"
                 title="添加分组"
                 icon="el-icon-folder-add"
                 size="mini"
                 @click="DirectoryOperation('add')" />
      <el-button class="w-btn"
                 round
                 type="primary"
                 title="重命名分组"
                 icon="el-icon-edit-outline"
                 size="mini"
                 @click="DirectoryOperation('rename')" />
      <el-button class="w-btn"
                 round
                 type="danger"
                 title="删除分组"
                 icon="el-icon-delete"
                 size="mini"
                 @click="DirectoryOperation('remove')" />
      <el-button class="w-btn"
                 round
                 type="success"
                 title="下载文件"
                 icon="el-icon-download"
                 size="mini"
                 @click="downloadFiles" />
    </div>
    <div class="wrap-tree">
      <el-tree ref="tree"
               :data="treeData"
               node-key="id"
               :props="defaultProps"
               :default-expand-all="true"
               show-checkbox
               :expand-on-click-node="false"
               :highlight-current="true"
               @node-click="treeNodeClick">
        <template #default="{ node , data}">
          <div class="custom-tree-node">
            <span :title="node.label"
                  :class="data.required?'node-span1 is-required':'node-span1'"
                  :style="{width: width-162+'px'}"
                  style="line-height:21px;">{{ node.label }}</span>
            <span v-if="!data.extension"
                  class="node-span2">
              <el-upload class="upload-demo"
                         :action="uploadapi"
                         :http-request="uploadSectionDirectory"
                         :show-file-list="showFileList"
                         @click="clickFileUploadBtn"
                         multiple
                         :on-success="
                    (res, file, fileList) => {
                      return uploadSuccess(res, file, fileList, node);
                    }
                  "
                         :file-list="fileList">

                <el-icon title="上传"
                         style="background-color:#409EFF;">
                  <Upload />
                </el-icon>
              </el-upload>
              <el-icon v-if="node.data.api"
                       title="获取"
                       style="background-color:#FFD700"
                       @click="getFileByApi(node.data.id,node.data.id,node.data.id)">
                <Link />
              </el-icon>
            </span>
            <span v-else>
              <span v-if="node.data.showButton">
                <el-icon title="预览"
                         v-if="isPhoto(node.data.extension)"
                         style="background-color:#E6A23C"
                         @click="showFile(node.data.id,node.data.extension)">
                  <View />
                </el-icon>
                <el-icon title="下载"
                         v-else
                         style="background-color:#67C23A"
                         @click="downloadFile(node.data.id,node.data.name)">
                  <Download />
                </el-icon>
                <el-icon title="删除"
                         style="background-color:#F56C6C;"
                         @click="fileRemove(node)">
                  <Close />
                </el-icon>
              </span>
              <span v-else>
                <el-upload class="upload-demo"
                           :action="uploadapi"
                           :http-request="uploadSectionFile"
                           :on-preview="handlePreview"
                           :on-remove="handleRemove"
                           :show-file-list="showFileList"
                           multiple
                           :limit="1"
                           @click="clickFileUploadBtn"
                           :on-exceed="handleExceed"
                           :on-success="
                    (res, file, fileList) => {
                      return uploadSuccess(res, file, fileList, node);
                    }
                  "
                           :file-list="fileList">
                  <el-icon title="上传"
                           style="background-color:#409EFF;">
                    <Upload />
                  </el-icon>

                </el-upload>

                <el-icon v-if="node.data.api"
                         title="获取"
                         style="background-color:#FFD700"
                         @click="getFileByApi(node.data.id,node.data.id,node.data.id)">
                  <Link />
                </el-icon>
              </span>
            </span>
          </div>
        </template>
      </el-tree>
    </div>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer lazy
                   :url-list="fileContent"
                   @close="
      () => {
        showViewer = false;
      }
    "
                   v-if="showViewer">
    <template #error>
      <div class="image-slot">
        <el-icon>
          <Loading />
        </el-icon>
      </div>
    </template>
  </el-image-viewer>
  <!-- txt预览 -->
  <el-dialog v-model="showFileDialogVisible"
             :fullscreen="isFullscreen"
             custom-class="dialog-pdf"
             :top="'12vh'"
             :width="'60%'"
             :close-on-click-modal="false">
             <template #title>
               <div class="my-header">
                <span>PDF预览</span>
                 <i class="el-icon-full-screen icon-full-screen" @click="isFullscreen=!isFullscreen"></i>
               </div>
             </template>
    <embed style="width: 100%; min-height: 66vh;height:100%;"
           frameborder="0"
           :src="fileContent[0]+'#view=FitH,top'+'&toolbar=0&navpanes=0&scrollbar=0'" />
  </el-dialog>
  <el-dialog v-model="updateDirectoryDialogVisible"
             :title="DirectoryOperationType === 'add' ? '新增分组' : '重命名分组'"
             :close-on-click-modal="false"
             custom-class="wp-fileDia"
             @closed="CloseDirectoryDialog"
             :width="400">
    <el-form ref="elForm"
             label-width="100px">
      <el-form-item label="分组名">
        <el-input v-model="DirectoryName"
                  placeholder="请输入分组名" />
      </el-form-item>
      <el-form-item label="根节点"
                    v-if="DirectoryOperationType === 'add'">
        <el-radio-group v-model="IsRootDirectory"
                        :disabled="IsRD">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="info"
                 @click="updateDirectoryDialogVisible = false">取消</el-button>
      <el-button type="success"
                 @click="AddOrRenameDirectory">确定</el-button>
    </template>

  </el-dialog>
  <el-dialog
    custom-class="progressStyle"
    v-model="uploadProgress"
    :before-close="closeProgress"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="材料列表"
    width="40%"
  >
    <div style="padding: 10px 20px">
      <el-table :data="percentageData" style="width: 100%">
        <el-table-column label="文件名" width="100">
          <template #default="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="上传进度">
          <template #default="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="scope.row.num"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="closeProgress"
          >关闭</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
let id = 1000
console.log(id)
// import UploadFile from "../../components/Upload";
import {
  Loading,
  Close,
  View,
  Upload,
  Download,
  Link,
} from '@element-plus/icons'
import { ElMessage, ElMessageBox } from 'element-plus'
import upload from '../../components/upload'
import { matchType } from '@/layout/config/tagsm.js'
import Guid from 'guid'
// import axios from 'axios'
export default {
  components: {
    // UploadFile,
    Loading,
    Close,
    View,
    Upload,
    Download,
    Link,
  },
  watch: {
    $route(to) {
      if (to.meta.isRegister) {
        let instanceId = this.$route.query.instanceId
        let fid = this.$route.query.fid
        this.getTreedataJson(instanceId, fid)
      }
    },
  },
  mounted() {
    this.taskId = this.$route.query.taskId
  },
  props: {
    width: {
      type: Number,
    },
  },
  data() {
    return {
      color: '#F56C6C',
      instanceId: '',
      fileList: [], //文件数组
      fileContent: [], //文件
      PicOrTxt: '',
      showViewer: false,
      ProjectName: '', //项目名称
      taskId: '',
      fId: '',
      timer: null,
      ywxtApi: this.$appConfig.apiUrls.ywxtApi,
      tableApi: this.$appConfig.apiUrls.tableApi,
      data: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled',
      },
      actionTreeNode: null,
      showFileDialogVisible: false,
      updateDirectoryDialogVisible: false,
      uploadapi: this.$appConfig.apiUrls.ywxtApi,
      treeClickCount: 0,
      DirectoryName: '',
      IsRootDirectory: false,
      IsRD: false,
      DirectoryOperationType: 'add',
      showFileList: false,
      uploadProgress: false,
      percentageData: [],
      count:0,
      isFullscreen:false,
      picArray:[],//图片文件数组
    }
  },
  methods: {
    closeProgress(){
      // if(this.count===this.percentageData.length){
      //   this.uploadProgress = false;
      //   this.percentageData = [];
      //   this.count=0;
      // }
    },
    async getTagName() {
      let tagName = await this.$ajax.post(
        this.ywxtApi + '/api/File/GetFileTagName'
      )
      if (!tagName.data.IsSuccess) {
        ElMessage({
          message: tagName.data.ErrorMessage,
          type: 'warning',
        })
      }
      return tagName.data.Data
    },
    async getFileList(fId) {
      this.fId = fId
      let tagName = await this.getTagName()
      let fList = await this.$ajax.post(
        this.ywxtApi +
          '/api/File/GetFileList?projectType=' +
          this.fId +
          '&instanceId=' +
          this.instanceId +
          '&groupName=&tagName=' +
          tagName
      )
      let data = fList.data.Data
      this.data =
        Object.prototype.toString.call(data) === '[object String]'
          ? JSON.parse(data)
          : data
    },
    //加载树结构
    async getTreedataJson(instanceId, fId, isUpload) {
      let conditions = []
      let me = this
      me.instanceId = instanceId
      me.actionTreeNode = null
      if(!isUpload && isUpload!='remove'){
        await this.getFileList(fId)
      }
      me.treeData = JSON.parse(JSON.stringify(me.data))
      conditions.push({
        Key: 'a.InstanceId',
        Value: instanceId,
        IsCondition: true,
        Operator: '=',
        LogicalOperator: 'and',
      })
      let para = {
        queryId: 'file_list',
        PageInfo: { pageNum: 1, pageSize: 1000 },
        conditions: conditions,
      }
      let res = await this.$ajax.post(
        this.tableApi + '/api/CommonTable/LoadData',
        JSON.stringify(para)
      )
      res = JSON.parse(res.data.data)
      if (!res.rows) {
        return
      }
      //console.log(res.rows)
      if (me.treeData != null) {
        let _treeData = []
        for (let index = 0; index < me.treeData.length; index++) {
          let data = res.rows.filter(
            (item) =>
              item.GroupName.toUpperCase() ===
              me.treeData[index].name.toUpperCase()
          )
          _treeData.push({
            id: me.treeData[index].id,
            name: me.treeData[index].name,
            required: me.treeData[index].required,
            children: [],
            api: me.treeData[index].api,
          })
          const rd = data.filter((item) => item.ParentName === null)
          for (let i = 0; i < rd.length; i++) {
            if (rd[i].IsFile) {
              if (
                this.getFullPath(
                  this.$refs.tree.getNode(me.treeData[index].id)
                ) ===
                me.treeData[index].name + '/'
              ) {
                _treeData[index].children.push({
                  id: rd[i].Id,
                  name: rd[i].ObjectName,
                  disabled: false,
                  showButton: true,
                  file: rd[i].ObjectName,
                  extension: rd[i].Extension,
                  children: [],
                })
              }
            }
          }
          me.getFileInfo(
            me.treeData[index].children,
            data,
            null,
            _treeData[index].children,
            me.treeData[index].id
          )
          
        }

        me.treeData = _treeData
        if(isUpload === true){
        ElMessageBox.alert('文件上传完成', '提示', { confirmButtonText: '确定',callback: () => {  
          me.uploadProgress = false;
          me.percentageData = [];
          me.count=0;
        }})
        }
        // me.showFileList = false;
      }
    },
    //获取根层结构获取文件
    getFileInfo(treedata, rowdata, parentName, list, nodeid) {
      //console.log(treedata)
            //获取当前分组下的所有文件
      const rd = rowdata.filter((item) => item.ParentName === parentName)
      for (let i = 0; i < rd.length; i++) {
        if (rd[i].IsFile) {
          //(this.getFullPath(this.$refs.tree.getNode(nodeid)))
          //console.log(this.getFileFullPath(rd[i], rowdata, rd[i].ParentName))
          if (
            this.getFullPath(this.$refs.tree.getNode(nodeid)) ===
            this.getFileFullPath(rd[i], rowdata, rd[i].ParentName) + '/'
          ) {
            list.push({
              id: rd[i].Id,
              name: rd[i].ObjectName,
              disabled: false,
              showButton: true,
              file: rd[i].ObjectName,
              extension: rd[i].Extension,
              children: [],
            })
          }
        }
      }
      if (treedata.length > 0) {
        for (let i = 0; i < treedata.length; i++) {
          if ('file' in treedata[i]) {
            let rd = rowdata.filter(
              (item) => item.ObjectName === treedata[i].name
            )
            // console.log(rd)
            // console.log(treedata[i])
            // console.log(treedata[i].name)
            let isHave = false
            for (let j = 0; j < rd.length; j++) {
              console.log(this.getFullPath(this.$refs.tree.getNode(nodeid)))
              console.log(rd[j])
              console.log(
                this.getFileFullPath(rd[j], rowdata, rd[j].ParentName) + '/'
              )
              if (
                this.getFullPath(this.$refs.tree.getNode(nodeid)) ===
                (rd[j].ParentName
                  ? this.getFileFullPath(rd[j], rowdata, rd[j].ParentName)
                  : rd[j].GroupName) +
                  '/'
              ) {
                isHave = true
                break
              }
            }
            if (!isHave) {
              list.push({
                id: treedata[i].id,
                name: treedata[i].name,
                disabled: false,
                showButton: false,
                file: '',
                extension: treedata[i].extension,
                children: [],
                api: treedata[i].api,
              })
            }
          } else {
            //分组
            list.push({
              id: treedata[i].id,
              name: treedata[i].name,
              required: treedata[i].required,
              children: [],
              api: treedata[i].api,
            })
            this.getFileInfo(
              treedata[i].children,
              rowdata,
              treedata[i].name,
              list[list.length - 1].children,
              treedata[i].id
            )
          }
        }
      }

    },
    //申请下载
    downloadFiles() {
      let me = this
      let checkNodes = this.$refs.tree.getCheckedNodes()
      let Ids = []
      for (let i = 0; i < checkNodes.length; i++) {
        if (checkNodes[i].showButton) {
          Ids.push(checkNodes[i].id)
        }
      }
      if (Ids.length <= 0) {
        ElMessage({
          type: 'warning',
          message: '在申请的下载列表中，无有效数据！',
        })
        return
      }
      let downloadurl = me.$appConfig.apiUrls.downloadApi+'/file/getzip?a=1'
      Ids.forEach(function(id){
        downloadurl += '&ids='
        downloadurl += id
      })
      let link =document.createElement('a')
      link.href=downloadurl
      document.body.append(link)
      link.click()
      document.body.removeChild(link)
    },
    isPhoto(type) {
      if (
        type.toUpperCase() === '.PNG' ||
        type.toUpperCase() == '.JPG' ||
        type.toUpperCase() == '.PDF'
      ) {
        return true
      } else {
        return false
      }
    },
    checkData(arr){
      for (let i = 0; i < arr.length; i++) {
        if(matchType(arr[i].name)==='image'){
          this.picArray.push(arr[i].id)
        }else if(arr[i].children && arr[i].children.length>0){
          this.checkData(arr[i].children)
        }
      }
    },
    //文件预览
    async showFile(objectId, fileType) {
      this.picArray = []
      this.fileContent = []
      this.fileContent = [
        this.ywxtApi + '/api/File/getFile?objectId=' + objectId,
      ]
      this.PicOrTxt = matchType(fileType)
      //console.log(this.PicOrTxt)
      if (this.PicOrTxt === 'image') {
        this.checkData(this.treeData);
        this.picArray = [...new Set([...this.picArray])]
        for (let i = 0; i < this.picArray.length; i++) {
          this.picArray[i] = this.ywxtApi + '/api/File/getFile?objectId=' + this.picArray[i]
        }
        this.fileContent = this.picArray;
        let n = this.fileContent.indexOf(this.ywxtApi + '/api/File/getFile?objectId=' + objectId);
        let arr = this.fileContent.slice(0, n);
        this.fileContent.splice(0, n);
        this.fileContent = [...this.fileContent, ...arr];
        this.showViewer = true
      } else if (this.PicOrTxt == 'pdf') {
        this.isFullscreen = false;
        this.showFileDialogVisible = true
      }
      //this.showViewer = true
    },
    downloadFile(objectId, fileName) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href =
        this.ywxtApi +
        '/api/File/getFile?objectId=' +
        objectId +
        '&isDownload=true'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    //删除文件
    fileRemove(node) {
      let id=node.data.id;
      let fileName=node.data.name;
      let me = this
      ElMessageBox.confirm('是否删除 ' + fileName + ' ?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$ajax.post(
            this.ywxtApi + '/api/File/DeleteFiles',
            JSON.stringify([id])
          ).then((res)=>{
          if (res.data.IsSuccess) {
            if (!node.data.required) {
              me.$refs.tree.remove(node)
              //删除
              let para = {
                InstanceId: me.instanceId,
                Json: JSON.stringify(me.$refs.tree.data),
              }
              me.data = me.$refs.tree.data;
              me.$ajax
                .post(me.ywxtApi + '/api/File/SetFileList', para)
                .then((res) => {
                  if (res.data) {
                    if (res.data.IsSuccess) {
                      ElMessage({
                        type: 'success',
                        message: '删除完成',
                      })
                    }else{
                      ElMessage({
                        type: 'error',
                        message: '删除失败',
                      })
                    }
                  }
                  me.getTreedataJson(me.instanceId, me.fId, 'remove')
                })
                .catch(function (error) {
                  console.log(error)
                  me.getTreedataJson(me.instanceId, me.fId)
                })
            }else{
              ElMessage({
                type: 'success',
                message: '删除完成',
              })
            }
          }else{
            ElMessage({
              type: 'error',
              message: '删除失败',
            })
          }
        })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消操作',
          })
        })
    },
    //上传
    uploadSectionFile(params) {
      console.log('执行1');
      //获取groupname以及文件结构
      let groupName = '',
        webkitRelativePath = '',
        parentNode = this.actionTreeNode.parent
      while (parentNode.data.name) {
        webkitRelativePath = parentNode.data.name + '/' + webkitRelativePath
        groupName = parentNode.data.name
        parentNode = parentNode.parent
      }
      const fileName = this.actionTreeNode.data.name
      webkitRelativePath = webkitRelativePath.replace(groupName + '/', '')
      if (webkitRelativePath.length > 0) {
        webkitRelativePath = webkitRelativePath + fileName
      }
      this.percentageData.push({num:0,name:fileName});
      this.uploadProgress = true;
      upload(
        params,
        fileName,
        groupName,
        this.instanceId,
        webkitRelativePath,
        '',
        this.callback,
        this.progressBar
      )
    },
    progressBar(total, num, name){
      let n =Math.round((num/total)*10000)/100.0
      if(this.percentageData.length>0){
        for (let i = 0; i < this.percentageData.length; i++) {
          if(this.percentageData[i].name==name){
            this.percentageData[i].num=n;
          }
        }
        let s=this.percentageData.filter((i)=>i.name==name).length
        if(s<1){
          this.percentageData.push({ num:n,name:name })
        }
      }else{
        this.percentageData.push({ num:n,name:name })
      }
      // console.log('步骤条数据：',this.percentageData);
      // console.log(total<=0?"0%":n+'%');
    },
    //上传
    uploadSectionDirectory(params) {
      let groupName = '',
        webkitRelativePath = '',
        parentNode = this.actionTreeNode
      while (parentNode.data.name) {
        webkitRelativePath = parentNode.data.name + '/' + webkitRelativePath
        groupName = parentNode.data.name
        parentNode = parentNode.parent
      }
      const fileName = params.file.name
      webkitRelativePath = webkitRelativePath.replace(groupName + '/', '')
      if (webkitRelativePath.length > 0) {
        webkitRelativePath = webkitRelativePath + fileName
      }
      this.percentageData.push({num:0,name:fileName});
      this.uploadProgress = true;
      upload(
        params,
        fileName,
        groupName,
        this.instanceId,
        webkitRelativePath,
        '',
        this.callback,
        this.progressBar
      )
    },
    //上传结束后事件
    callback(data) {
      //   console.log(data)
      let me = this
      this.$ajax
        .get(
          this.$appConfig.apiUrls.ywxtApi +
            '/api/File/UploadedSuccess?objectid=' +
            data.Data.objectId
        )
        .then(function (response) {
          console.log(response)
          me.count++
          if(me.count===me.percentageData.length){
            me.getTreedataJson(me.instanceId, me.fId, true)
            //ElMessageBox.alert('文件上传完成', '提示', { confirmButtonText: '确定',callback: () => {    }})
          }
          // if (me.timer) {
          //   clearTimeout(me.timer)
          // }
          // me.timer = setTimeout(() => {
          //   me.getTreedataJson(me.instanceId, me.fId)
          // }, 1000)
        })
    },
    clickFileUploadBtn() {
      // this.fileList = [];
      // this.showFileList = true;
      this.treeClickCount = 0
    },
    //获取当前活动节点
    treeNodeClick(data, node) {
      const me = this
      me.actionTreeNode = node
      me.treeClickCount++
      //单次点击次数超过2次不作处理,直接返回,也可以拓展成多击事件
      if (me.treeClickCount >= 2) {
        return
      }
      //计时器,计算300毫秒为单位,可⾃⾏修改
      window.setTimeout(() => {
        if (me.treeClickCount == 1) {
          //把次数归零
          me.treeClickCount = 0
          //单击事件处理
          console.log('单击事件,可在此处理对应逻辑')
        } else if (me.treeClickCount > 1) {
          //把次数归零
          me.treeClickCount = 0
          //双击事件
          console.log('双击事件,可在此处理对应逻辑')
          if (node.data.extension && me.isPhoto(node.data.extension)) {
            me.showFile(node.data.id, node.data.extension)
          }
        }
      }, 300)
    },
    getFullPath(node, path = '') {
      let result = path
      //console.log(node)
      if (node.data.name) {
        path = node.data.name + '/' + path
        result = this.getFullPath(node.parent, path)
      }
      return result
    },
    getFileFullPath(file, rows, path = '') {
      let result = path
      const rs = rows.find((r) => r.Id === file.ParentId)
      if (rs) {
        result = (rs.ParentName ? rs.ParentName : rs.GroupName) + '/' + result
        result = this.getFileFullPath(rs, rows, result)
      }
      return result
    },
    //示例代码
    //暂时无意义
    handleRemove(file, fileList) {
      console.log('控制删除', file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    //上传文件超过一个时提醒
    handleExceed() {
      ElMessage({
        message: '请勿重复上传',
        type: 'warning',
      })
    },
    async getFileByApi(flowId, taskId, instanceId) {
      let para = {
        flowId: flowId,
        taskId: taskId,
        instanceId: instanceId,
      }
      let res = await this.$ajax.post(
        this.ywxtApi + '/api/File/GetFileByApi',
        JSON.stringify(para)
      )
      if (!res.data.IsSuccess)
        ElMessage({
          message: res.data.ErrorMessage,
          type: 'warning',
        })
    },
    DirectoryOperation(type) {
      let actionTreeNodeJson = ''
      if (this.actionTreeNode) {
        actionTreeNodeJson = JSON.stringify(this.actionTreeNode.data)
      }

      this.DirectoryOperationType = type
      switch (type) {
        case 'add':
          if (this.actionTreeNode == null) {
            this.updateDirectoryDialogVisible = true
            this.DirectoryName = ''
            this.IsRootDirectory = true
            this.IsRD = true
          } else if (
            this.actionTreeNode.data &&
            this.actionTreeNode.data.file == undefined
          ) {
            this.updateDirectoryDialogVisible = true
            this.DirectoryName = ''
            this.IsRootDirectory = false
            this.IsRD = false
          } else {
            ElMessage({
              type: 'warning',
              message: '请选择正确的分组！',
            })
          }
          break
        case 'rename':
          if (
            actionTreeNodeJson.indexOf('file') > 0 &&
            actionTreeNodeJson.indexOf('extension') > 0
          ) {
            ElMessage({
              type: 'warning',
              message: '该分组中存在文件，禁止操作！',
            })
            return
          }
          if (
            this.actionTreeNode &&
            this.actionTreeNode.data.file == undefined
          ) {
            this.updateDirectoryDialogVisible = true
            this.DirectoryName = this.actionTreeNode.data.name
          } else {
            ElMessage({
              type: 'warning',
              message: '请选择需要修改的分组！',
            })
          }
          break
        case 'remove':
          if (
            actionTreeNodeJson.indexOf('file') > 0 &&
            actionTreeNodeJson.indexOf('extension') > 0
          ) {
            ElMessage({
              type: 'warning',
              message: '该分组中存在文件，禁止操作！',
            })
            return
          }
          this.RemoveDirectory()
          break
        default:
          break
      }
    },
    AddOrRenameDirectory() {
      const me = this
      if (!me.DirectoryName) {
        ElMessage({
          type: 'warning',
          message: '分组名不能为空!',
        })
        return
      }
      if (me.DirectoryOperationType === 'add') {
        const gid = Guid.raw()
        let newNode = {
          id: gid,
          name: me.DirectoryName,
          children: [],
          required: false,
        }
        if (me.IsRootDirectory) {
          me.actionTreeNode = null
        }
        this.$refs.tree.append(newNode, me.actionTreeNode)
        //console.log(JSON.stringify(me.treeData))
      } else {
        me.actionTreeNode.data.name = me.DirectoryName
      }
      let para = {
        InstanceId: me.instanceId,
        Json: JSON.stringify(me.$refs.tree.data),
      }
      me.$ajax
        .post(me.ywxtApi + '/api/File/SetFileList', JSON.stringify(para))
        .then((res) => {
          if (res.data) {
            if (res.data.IsSuccess) {
              ElMessage({
                type: 'success',
                message: '操作完成',
              })
            }
          }
          me.getTreedataJson(me.instanceId, me.fId)
        })
        .catch(function (error) {
          console.log(error)
          me.getTreedataJson(me.instanceId, me.fId)
        })

      me.updateDirectoryDialogVisible = false
    },
    RemoveDirectory() {
      const me = this
      if (!me.actionTreeNode || me.actionTreeNode.data.file != undefined) {
        ElMessage.error('请选择需要删除的分组！')
        return
      }

      ElMessageBox.confirm(
        '是否确认删除 <strong>' + me.actionTreeNode.data.name + '</strong> ?',
        '确认提示',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(async () => {
          me.$refs.tree.remove(me.actionTreeNode)
          //删除
          let para = {
            InstanceId: me.instanceId,
            Json: JSON.stringify(me.$refs.tree.data),
          }
          me.$ajax
            .post(me.ywxtApi + '/api/File/SetFileList', para)
            .then((res) => {
              if (res.data) {
                if (res.data.IsSuccess) {
                  ElMessage({
                    type: 'success',
                    message: '删除完成',
                  })
                }
              }
              me.getTreedataJson(me.instanceId, me.fId)
            })
            .catch(function (error) {
              console.log(error)
              me.getTreedataJson(me.instanceId, me.fId)
            })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消操作',
          })
        })
    },
    CloseDirectoryDialog() {
      // this.actionTreeNode = null
      this.IsRootDirectory = false
      this.DirectoryOperationType = 'add'
      this.DirectoryName = ''
    },
  },
}
</script>
<style>
.dialog-pdf .el-dialog__body {
  height: calc(100% - 100px);
  padding: 20px;
}
.progressStyle .el-dialog__body {
  padding:0;
}
.progressStyle .el-dialog__footer {
  padding:10px 20px;
}
.icon-full-screen{
  cursor: pointer;
  margin-left: 20px;
}
.icon-full-screen:hover{
  color: var(--el-color-primary);
  font-size: 19px;
}
</style>
<style scoped>

/* .wrap-tree :deep(.el-tree-node){
  position: relative;
}
.upload-demo :deep(ul){
  z-index: 99;
  position: absolute;
  left: 60px;
  min-width: 180px;
  width: 50%;
  top: 12px;
  background: #ffffff;
  -moz-box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 25%);
  box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 25%);
}
.upload-demo :deep(ul li){
  font-size: 12px;
}
.upload-demo :deep(ul li:first-child){
  margin-top: 5px;
} */
.upload-demo {
  display: inline-block;
  margin-right: 10px;
}
/*  */
.wrap-tree {
  border-top: 1px solid #d9d7d7;
}
.wrap-tree :deep(.el-tree-node) {
  margin-top: 5px;
  padding-bottom: 2px;
}
.wrap-tree
  :deep(.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content) {
  background-color: #c3daf1;
}
.wrapbtn {
  float: left;
  padding-top: 12px;
  padding-right: 5px;
}
/* 、、、 */
.wp > span {
  font-size: 18px;
  padding-left: 5px;
  line-height: 40px;
}
.wrap-mp {
  /* border: 1px solid; */
  background: #ffffff;
  height: 50%;
  margin-bottom: 20px;
  border-radius: 3px;
}

.wrap-info {
  /* border:1px solid red; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  width: 100%;
}

.edit-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.edit-header a {
  cursor: pointer;
}

.custom-tree-node {
  width: 100%;
}
:deep(.el-tree-node__content) {
  height: 15px;
}
.custom-tree-node .node-span1 {
  font-size: 12px;
  align-items: center;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-tree-node .node-span2 {
  float: right;
}
.custom-tree-node span:last-child i {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
  margin-right: 8px;
}
.is-required {
  color: red;
}
.is-required::before {
  content: '*';
  color: red;
}

.w-btn {
  padding: 0px 8px;
  margin-left: 8px;
}
.w-btn :deep(i) {
  font-size: 16px;
}
</style>
<style>
.wp-fileDia .el-dialog__header {
  border-bottom: 1px solid #edebeb;
  padding: var(--el-dialog-padding-primary) !important;
}
.wp-fileDia .el-dialog__body {
  padding-top: 15px;
  padding-bottom: 0px;
}
.wp-fileDia .el-dialog__footer {
  padding-top: 0px;
}
</style>
